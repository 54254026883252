<template>
  <UiMenu
    bottom
    left
    offset-y
    :nudge-bottom="2"
    :min-width="156"
  >
      <template v-slot:activator="{ on, attrs }">
        <UiBtn v-bind="attrs" v-on="on" @click.prevent icon color="gray-60">
          <IconDotsH width="18"/>
        </UiBtn>
      </template>
      <v-list nav dense color="gray-10">
        <v-list-item
          :disabled="!getPermission(project.id).dashboard['can-duplicate-dashboard']"
          @click="duplicateProject">
          <IconAddNewItem width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">Duplicate project</v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="$router.push({name: 'ProjectEdit', params: {project_id: project.id}})">
          <IconInfoFilled width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">Project specification</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!getPermission(project.id).dashboard['can-share-dashboard']"
          @click="shareProject">
          <IconShare width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">Share project</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="project.status === $config.project.status.archived"
          :disabled="!getPermission(project.id).dashboard['can-archive-dashboard']"
          @click="updateProjectStatus('rollback')">
          <IconArchive width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">Unarchive</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="project.status !== $config.project.status.archived"
          :disabled="!getPermission(project.id).dashboard['can-archive-dashboard']"
          @click="archiveProject">
          <IconArchive width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">Archive</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="!getPermission(project.id).dashboard['can-delete-dashboard']"
          @click="deleteProject">
          <IconDelete width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1">
            Delete
          </v-list-item-title>
        </v-list-item>
        <v-list-item @click="onCompleteProject" :disabled="!getPermission(project.id)['project-s-drawer']['complete-project']">
          <IconCheck width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1"
            >Mark project as completed</v-list-item-title
          >
        </v-list-item>
        <v-list-item @click="onMoveProject" :disabled="!canMoveProject">
          <IconArrowRight width="18" class="mr-2 gray-60--text" />
          <v-list-item-title class="text-captions-1"
            >Move Project</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </UiMenu>
</template>

<script>
import {mapGetters} from "vuex";
import UiMenu from "@/components/UI/UiMenu";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin"

export default {
  name: 'ProjectMenu',
  components: {
    IconDotsH: () => import('@/components/icons/IconDotsH'),
    IconAddNewItem: () => import("@/components/icons/IconAddNewItem"),
    IconInfoFilled: () => import("@/components/icons/IconInfoFilled"),
    IconShare: () => import("@/components/icons/IconShare"),
    IconArchive: () => import("@/components/icons/IconArchive"),
    IconDelete: () => import("@/components/icons/IconDelete"),
    IconCheck: () => import("@/components/icons/IconCheck"),
    IconArrowRight: () => import("@/components/icons/IconArrowRight"),
    UiMenu,
    UiBtn
  },
  mixins: [projectCompleteMixin],
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'getDashboardFilters',
      'getProjectsList',
      'getPermission',
      'getActiveSpace',
    ]),
    canMoveProject() {
      const {spaceAdmin} = this.$config.space.userRole;
      if (!this.getActiveSpace) {
        return false
      } else {
        return this.getActiveSpace.currentSpaceUser.user_role.name === spaceAdmin
      }
    },
  },
  methods: {
    async duplicateProject() {
      if (!this.getPermission(this.project.id).dashboard['can-duplicate-dashboard']) return;

      this.$store.dispatch('setDashboardLoading', true);

      try {
        const res = await this.$api.project.duplicate(this.project.id);
        await this.$store.dispatch('setProjects', [res.data, ...this.getProjectsList]);

        const permissions = await this.$api.project.permissions(this.getActiveSpace?.id);
        await this.$store.dispatch('setPermissions', permissions.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch('setDashboardLoading', false);
      }
    },
    async updateProjectStatus(status) {
      if (!this.getPermission(this.project.id).dashboard['can-archive-dashboard']) return;

      await this.$store.dispatch('updateDashboardProject', {
        id: this.project.id,
        loading: true
      });

      try {
        const res = await this.$api.project.updateStatus(this.project.id, status);
        let projects;
        if (this.$store.getters.getDashboardFilters.status.includes(res.data.status.toString())) {
          projects = this.getProjectsList.map(project => project.id === res.data.id ? {...project, status: res.data.status} : project)
        } else {
          projects = this.getProjectsList.filter(project => project.id !== this.project.id);
        }
        await this.$store.dispatch('setProjects', projects);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch('updateDashboardProject', {
          id: this.project.id,
          loading: false
        });
      }
    },
    archiveProject() {
      if (!this.getPermission(this.project.id).dashboard['can-archive-dashboard']) return;
      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to archive this project?',
          description: 'Archiving your project will no longer appear in your project list',
          confirmBtnText: 'Archive',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            await this.updateProjectStatus('archived')
          }
        }
      });
    },
    shareProject() {
      if (!this.getPermission(this.project.id).dashboard['can-share-dashboard']) return;

      this.$store.dispatch('openModal', {
        modalName: 'shareProjectModal',
        data: {
          projects: [this.project]
        }
      });
    },
    deleteProject() {
      if (!this.getPermission(this.project.id).dashboard['can-delete-dashboard']) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete this project?',
          description: 'If you delete a project all its files will be archived and can be viewed through the files section',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            await this.$store.dispatch('updateDashboardProject', {
              id: this.project.id,
              loading: true
            });

            try {
              await this.$api.project.delete(this.project.id);
              const projects = this.getProjectsList.filter(project => project.id !== this.project.id);
              await this.$store.dispatch('setProjects', projects);
            } catch (error) {
              await this.$store.dispatch('updateDashboardProject', {
                id: this.project.id,
                loading: false
              });
              console.error(error);
            }
          }
        }
      });
    },
    async onCompleteProject() {

      await this.completeProject([this.project.id], async (res) => {
        await this.$store.dispatch('updateDashboardProject', {
          id: this.project.id,
          loading: true
        });

        let projects;

        if (this.$store.getters.getDashboardFilters.status.includes(res.data[0].status.toString()) || !this.$store.getters.getDashboardFilters.status.length) {
          projects = this.getProjectsList.map(project => project.id === res.data[0].id ? {...project, status: res.data[0].status} : project)
        } else {
          projects = this.getProjectsList.filter(project => project.id !== this.project.id);
        }
        await this.$store.dispatch('setProjects', projects);

        await this.$store.dispatch('updateDashboardProject', {
          id: this.project.id,
          loading: false
        });
      })
    },
    onMoveProject() {
      this.$store.dispatch('openModal', {
        modalName: 'moveProjectFolderModal',
        data: {
          type: 'project',
          ids: [this.project.id],
          folderId: this.project?.folder?.id,
          spaceId: this.project.space_id
        },
        handlers: {
          onUpdate: async () => {
            const promises = [this.$api.spaces.list()]

            if(this.$route.name === 'Space') {
              promises.push(this.$eventBus.$emit('reloadProjectsAndFolders'))
            } else {
              const projects = this.getProjectsList.filter(project => project.id !== this.project.id);
              await this.$store.dispatch('setProjects', projects);
            }
            const [spaces] = await Promise.all(promises)
            await this.$store.dispatch('setSpaces', spaces.data);
          },
          onClose: () => {},
          onCreate: () => {}
        },
      });
    }
  },
}
</script>
