<template>
  <v-chip label v-bind="$attrs" class="white--text font-weight-bold text-captions-2 px-2 py-1 rounded-sm" small>
    <slot/>
  </v-chip>
</template>

<script>
export default {
  name: 'UiBadge',
}
</script>
