<template>
  <UiGridCard
    :item="folder"
    :itemStatus="folder.status"
    :selectable="false"
    :selected="folder.checked"
    :to="go(folder)"
  >

    <template #header>
      <div class="d-flex justify-space-between py-3 px-3">
        <UiBtn v-if="folder.last_comment_notification_data" color="gray-80" icon @click.prevent="goToComment(folder.last_comment_notification_data)" class="ml-n2">
          <v-badge color="accent" dot overlap bordered :value="true">
            <IconChat width="16" class="mt-1"/>
          </v-badge>
        </UiBtn>
        <UiSelectedIndicator
          :selected="folder.checked"
          @click.prevent="updateFolder({id: folder.folder_id, checked: !folder.checked})"
        />
      </div>
    </template>
    <template #badges>
      <UiBadge color="primary" v-if="folder.is_new">New</UiBadge>
      <UiBadge color="in_review" v-if="folder.review_needed">Review Needed</UiBadge>
      <UiBadge color="revision_needed" v-if="folder.revision_needed">Revision needed</UiBadge>
    </template>
    <template #image>
      <div class="card-image d-flex align-center justify-center flex-grow-1">
        <IconFolder class="folder-icon gray-30--text mb-3"/>
      </div>
    </template>

    <template #bottom>
      <div class="card-bottom__top mb-1">
        <v-tooltip top color="accent">
          <template v-slot:activator="{ on, attrs }">
            <h6 class="card-bottom__title font-weight-semi-bold text-truncate" v-bind="attrs"
                v-on="on">{{ folder.title }}</h6>
          </template>
          <span class="white--text font-weight-medium ">{{ folder.title }}</span>
        </v-tooltip>
      </div>
      <div class="card-bottom__content d-flex justify-space-between flex-wrap">
        <div class="card-bottom__content__left">
          <div>Created: {{format(new Date(folder.created_at), 'dd/MM/yyyy')}}</div>
          <div class="gray-80--text"><strong>Updated: {{ timeDistance(folder.updated_at) }}</strong></div>
        </div>
        <div class="card-bottom__content__right">

        </div>
      </div>
      <div class="card-bottom__actions d-flex justify-space-between mt-2">
        <div>
          <template v-for="(member, idx) in folder.team">
            <v-tooltip color="accent" top :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <UiAvatar
                  v-if="idx <= 1"
                  v-bind="attrs" v-on="on"
                  :key="idx" size="22"
                  :text="member.user.first_name"
                  :src="member.user.avatar && member.user.avatar.url || ''"
                  class="card-bottom__actions__avatar mr-2"
                />
              </template>
              <span>{{ member.user.first_name }} {{ member.user.last_name }}</span>
            </v-tooltip>
          </template>
          <UiAvatar
            v-if="folder.total_users && folder.total_users > 2"
            size="22"
            :text="`${folder.total_users - 2}`"
            :src="null"
          />
        </div>
        <div>
          <FolderMenu :folder="folder" :loadProjectsAndFolders="loadProjectsAndFolders"/>
        </div>
      </div>

      <div v-if="folder.loading" class="absolute inset-0 d-flex justify-center align-center gray-30"
            style="z-index: 2;" @click.prevent>
        <v-progress-circular
          :size="64"
          color="accent"
          indeterminate
        />
      </div>
    </template>
  </UiGridCard>
</template>

<script>
import {mapGetters} from "vuex";
import UiGridCard from "@/components/UI/UiGridCard"
import UiAvatar from "@/components/UI/UiAvatar";
import FolderMenu from "@/components/folder/FolderMenu";
import {format, differenceInDays, formatDistanceToNow} from "date-fns";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiBadge from "@/components/UI/UiBadge.vue";
import IconChat from "@/components/icons/IconChat.vue";
import UiBtn from "@/components/UI/UiBtn.vue";

export default {
  name: 'FolderGridCard',
  components: {
    UiBtn,
    IconChat,
    UiBadge,
    UiSelectedIndicator,
    UiGridCard,
    UiAvatar,
    IconFolder: () => import('@/components/icons/IconFolder'),
    FolderMenu
  },
  props: {
    size: {
      type: String,
      default: 'large'
    },
    folder: {
      type: Object,
      default: () => {}
    },
    loadProjectsAndFolders: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsList',
    ]),
  },
  methods: {
    format,
    updateFolder(data) {
      if(this.getProjectsList.find(el => !el.is_folder && el.checked)) {
        return
      }
      this.$store.dispatch('updateDashboardProjectFolder', data);
    },
    timeDistance(date) {
      const now = new Date().setHours(0, 0, 0, 0);
      if (differenceInDays(new Date(date), now) < 0) {
        return format(new Date(date), 'dd/MM/yyyy');
      } else {
        const res = formatDistanceToNow(new Date(date), {addSuffix: true});
        return res.replace('about ', '');
      }
    },
    go(folder) {
      return  {name: 'Folder', params: {id: folder.folder_id}};
    },
    goToComment(data) {
      this.$store.state.ProjectDesign.activeDesignMode = this.$config.project.designModes.task;

      this.$router.replace({
        name: 'DesignViewPage',
        params: {project_id: data.project_id},
        query: {
          module_id: data.module_id,
          style_id: data.style_id,
          file_id: data.file_id,
          comment_id: data.comment_id,
          task_id: data?.task_id || undefined,
          view_mode: this.$config.project.designModes.task,
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.card-image {
  // background: var(--v-gray-30-base);
  background: #fff;

  img {
    object-fit: cover;
  }
}

.card-bottom {
  &__title {
    font-size: 14px;
    color: var(--v-gray-100-base);
  }

  &__content {
    font-size: 10px;
    color: var(--v-gray-60-base);

    &__right {
      color: var(--v-gray-80-base);

      .dark {
        color: var(--v-gray-100-base);
      }
    }
  }

  &__actions {
    &__avatar {
      border-color: var(--v-accent-base) !important;

      &:nth-child(2) {
        border-color: var(--v-accent-60-base) !important;
      }
    }
  }
}
.folder-icon {
  width: 50%;
}
</style>
