<template>
  <div class="d-flex align-center">
    <div class="text-captions-1 items mr-2">Page items:</div>
    <div>
      <UiSelect
        :value="Number(this.$route.query.count || 15)"
        @change="onChange"
        class="select"
        name="select1"
        :items="items"
        item-text="label"
        item-value="value"
        hide-details
      />
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/UI/UiSelect.vue";

export default {
  name: "UiListItemsToShow",
  components: {UiSelect},
  data() {
    return {
      items: [
        { label: "15", value: 15 },
        { label: "30", value: 30 },
        { label: "60", value: 60 },
      ]
    }
  },
  methods: {
    onChange(count) {
      this.$router.$updateQueryParams({page: 1, count: count});
      this.$emit('update', count)
    }
  }
}
</script>

<style scoped lang="scss">
.items {
  color: #333
}
.select {
  max-width: 60px;
  :deep(.v-input.ui-select) {
    .v-input__slot {
      padding-left: 10px !important;
      .v-select__selection {
        font-weight: 600;
        color: var(--v-gray-100-base)
      }
    }
  }
}
</style>
