<template>
  <div>
    <v-pagination
      :value="Number(this.$route.query.page || 1)"
      @input="onChangePage"
      :length="length"
      :total-visible="totalVisible"
      prev-icon="Prev"
      next-icon="Next"
      class="list-pagination"
    />
  </div>
</template>
<script>
export default  {
  props: {
    length: {
      type: Number,
      default: 10
    },
    totalVisible: {
      type: Number,
      default: 7
    }
  },
  methods: {
    onChangePage(page) {
      this.$router.$updateQueryParams({page: page});
      this.$emit('update', page)
    }
  }
}
</script>
<style lang="scss" scoped>
.list-pagination {
  min-width: 390px;
}
.list-pagination :deep(.v-pagination__item) {
  box-shadow: none;
  border: 1px solid var(--v-gray-30-base);
}
.list-pagination :deep(.v-pagination__navigation) {
  box-shadow: none;
  i {
    font-size: 12px;
    font-family: Poppins, Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #333
  }
}
.list-pagination :deep(.v-pagination__navigation--disabled) {
  i {
    color: var(--v-gray-30-base);
  }
}
</style>
