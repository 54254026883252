<template>
  <div v-if="loading" class="d-flex justify-center pt-16">
    <v-progress-circular
      :size="100"
      color="accent"
      indeterminate
    />
  </div>
  <div v-else class="dashboard-page d-flex flex-column fill-height">
    <PageHeader>
      <div class="d-flex justify-space-between align-center space-x-4">
<!--        <div class="d-flex align-center">-->
          <UiBtn icon class="mr-1" :to="{name: 'Space', params: {id: getActiveFolder.space_id}}">
            <IconArrowLeft width="16" style="margin-top: 2px;"/>
          </UiBtn>
          <h1 class="text-heading text-truncate mr-auto">Folder
            {{ getActiveFolder ? `for ${getActiveFolder.title}` : '' }}
          </h1>
<!--        </div>-->
        <div class="flex-shrink-0">
          <UiBtn
            color="accent"
            @click="editFolder"
            class="flex-shrink-0 mr-2"
            :disabled="!canEditFolder"
          >
            <IconFolderMini width="14" class="mr-2"/>
            Edit Folder
          </UiBtn>
          <UiBtn
            color="accent"
            :to="{name: 'ProjectCreate', query: {space_id: getActiveSpace.id, folder_id: getActiveFolder.id}}" class="flex-shrink-0"
            :disabled="!canCreateProject"
          >
            <IconAddNewItem width="14" class="mr-2"/>
            New Project
          </UiBtn>
        </div>
      </div>
    </PageHeader>

    <v-container>
      <div class="d-flex align-center justify-space-between mb-9">
        <div class="d-flex">
          <!-- temporary hardcode change view on click -->
          <UiGridViewType class="mr-6" :list="$config.project.gridView.thumbnail"
                          :active="(getGridView.dashboard.gridView.type === 'thumbnail' && getGridView.dashboard.gridView)"
                          @setGridView="setGridView({key: 'dashboard', value: {gridView: $event, isGrid: true}})"/>
          <UiGridViewType class="mr-6" :list="$config.project.gridView.list"
                          :active="(getGridView.dashboard.gridView.type === 'list' && getGridView.dashboard.gridView)"
                          @setGridView="setGridView({key: 'dashboard', value: {gridView: $event, isGrid: false}})"/>
        </div>
        <div class="d-flex align-center">
          <UiFilter
            :filterData="filterData"
            offset-y
            allow-overflow
            left
            @setFilters="setFilters"
            openFilterBtnClasses="mr-6"
          />
          <UiSort
            class="mr-6"
            mandatory1
            mandatory2
            :list="sortList1"
            :list2="sortList2"
            :value1="sort_1"
            :value2="sort_2"
            @setSort1="value => this.setSort('sort_1', value)"
            @setSort2="value => this.setSort('sort_2', value)"
          />
          <UiSearch style="width: 194px" @onSearch="searchProject"/>
        </div>
      </div>

      <div v-if="$store.getters.getDashboardProjectsLoading" class="d-flex justify-center pt-16">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </div>

      <div v-if="!$store.getters.getDashboardProjectsLoading && $store.getters.getProjectsList.length">
        <ProjectsGridView v-show="getGridView.dashboard.isGrid" :size="getGridView.dashboard.gridView.slug"/>
        <ProjectsListView v-show="!getGridView.dashboard.isGrid" :size="getGridView.dashboard.gridView.slug"/>
        <PageFooter v-if="showBulkActions" class="px-0">
          <BulkActionsBar :loadProjects="loadProjects"/>
        </PageFooter>
      </div>
      <div v-if="paginationMeta.total > 15 && !$store.getters.getDashboardProjectsLoading" class="d-flex justify-space-between align-center w-full mt-7">
        <UiListItemsFromTo :from="paginationMeta.from" :to="paginationMeta.to" :total="paginationMeta.total" />
        <UiPagination @update="loadProjects" :length="paginationMeta.last_page" />
        <UiListItemsToShow @update="loadProjects" />
      </div>
      <ShareProjectModal/>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ProjectsGridView from "@/views/dashboard/ProjectsGridView";
import ProjectsListView from "@/views/dashboard/ProjectsListView";
import ShareProjectModal from "@/components/modals/ShareProjectModal";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import UiSearch from "@/components/UI/UiSearch";
import UiGridViewType from "@/components/UI/UiGridViewType"
import UiBtn from "@/components/UI/UiBtn";
import UiSort from "@/components/UI/UiSort"
import UiFilter from "@/components/UI/UiFilter"
import BulkActionsBar from "@/views/dashboard/BulkActionsBar";
import UiPagination from "@/components/UI/UiPagination.vue";
import UiListItemsToShow from "@/components/UI/UiListItemsToShow.vue";
import UiListItemsFromTo from "@/components/UI/UiListItemsFromTo.vue";

export default {
  name: 'Folder',
  components: {
    UiListItemsFromTo, UiListItemsToShow, UiPagination,
    IconAddNewItem: () => import('@/components/icons/IconAddNewItem'),
    IconFolderMini: () => import("@/components/icons/IconFolderMini"),
    IconArrowLeft: () => import("@/components/icons/IconArrowLeft"),
    PageHeader,
    PageFooter,
    ShareProjectModal,
    ProjectsGridView,
    ProjectsListView,
    UiSearch,
    UiGridViewType,
    UiBtn,
    UiSort,
    UiFilter,
    BulkActionsBar,
  },
  data() {
    return {
      sortList1: [
        {name: 'Name', slug: 'title'},
        {name: 'Modified', slug: 'updated_at'},
        {name: 'Modified By', slug: 'updated_by'},
        {name: 'Due Date', slug: 'due_date'}
      ],
      sortList2: [
        {name: 'Ascending', slug: 'asc'},
        {name: 'Descending', slug: 'desc'}
      ],
      filterData: [],
      sort_1: {name: 'Modified', slug: 'updated_at'},
      sort_2: {name: 'Descending', slug: 'desc'},
      search: '',
      loading: true,
      projectsLoading: false,
      page: null,
      paginationMeta: {
        page: 1,
        from: 1,
        last_page: 1,
        per_page: 20,
        to: 1,
        total: 1,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getAuthId',
      'getUser',
      'getProjectsList',
      'getDashboardFilters',
      'getActiveSpace',
      "getGridView",
      'getSpaces',
      'getActiveFolder'
    ]),
    showBulkActions() {
      return this.getProjectsList.some(project => project.checked);
    },
    canCreateProject() {
      const {projectOwner, projectManager} = this.$config.project.userRole;
      if (!this.getActiveSpace) {
        return true
      } else {
        return this.getActiveSpace.currentSpaceUser.project_roles.some(role => role.name === projectOwner || role.name === projectManager)
      }
    },
    canEditFolder() {
      const {spaceAdmin} = this.$config.space.userRole;
      if (!this.getActiveSpace) {
        return false
      } else {
        return this.getActiveSpace?.currentSpaceUser?.user_role.name === spaceAdmin
      }
    }
  },
  async created() {
    const {search = '', category, status, workflow_id, sort_1, sort_2} = this.$route.query;
    const filters = {
      category: category === '' || category === undefined ? [] : category.split(',').map(i => +i),
      status: status === '' || status === undefined ? [] : status.split(',').map(i => +i),
      workflow_id: workflow_id === '' || workflow_id === undefined ? [] : workflow_id.split(',').map(i => +i),
    };

    this.search = search;

    if (sort_1 !== '' && sort_1 !== undefined) {
      this.sort_1 = this.sortList1.find(sort => sort.slug === sort_1);
    }

    if (sort_2 !== '' && sort_2 !== undefined) {
      this.sort_2 = this.sortList2.find(sort => sort.slug === sort_2);
    }

    await this.$store.dispatch('setActiveFolder', this.$route.params.id)

    filters.space_id = this.getActiveSpace?.id || null
    await this.$store.dispatch('setDashboardFilters', filters);


    const [projects] = await Promise.all([this.loadProjects(), this.initFiltersData()])

    if (projects.length !== this.$store.state.Project.permissions.length) {
      const permissions = await this.$api.project.permissions(this.getActiveSpace?.id);
      await this.$store.dispatch('setPermissions', permissions.data);
    }

    this.loading = false;
  },
  methods: {
    ...mapActions([
      'setGridView'
    ]),
    async loadProjects() {
      try {
        this.$store.dispatch('setDashboardLoading', true);
        const res = await this.$api.project.list({
          ...this.getDashboardFilters,
          // count: -1,
          count: this.$route.query.count || 15,
          page: this.$route.query.page || 1,
          search: this.search,
          sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
          folder_id: this.$route.params.id
        });

        await this.$store.dispatch('setProjects', res.data);
        this.paginationMeta = res.meta
        this.$store.dispatch('setDashboardLoading', false);
        return res.data;
      } catch (error) {
        this.$store.dispatch('setDashboardLoading', false);
        console.error(error);
      }
    },
    async setFilters(v) {
      this.filterData = v
      const filters = {}
      this.filterData.forEach(el => {
        if (Array.isArray(el.activeFilters)) {
          filters[el.type] = el.activeFilters.map(i => i.toString())
        } else {
          if (el.activeFilters) {
            filters[el.type] = [...el.activeFilters]
          } else {
            filters[el.type] = []
          }
        }
      })

      this.$store.dispatch('setDashboardFilters', filters);
      this.$store.dispatch('setDashboardLoading', true);

      this.$router.$updateQueryParams({...filters, page: 1});

      await this.loadProjects();
      this.$store.dispatch('setDashboardLoading', false);
    },
    async initFiltersData() {
      const existingFilters = this.getDashboardFilters
      const [categories, workflowProcess] = await Promise.all([this.$api.category.list(), this.$api.workflowScheme.list()]);

      const statuses = Object.keys(this.$config.project.statusLabels).reduce((acc, status) => {
        acc.push({id: +status, title: this.$config.project.statusLabels[status]});
        return acc;
      }, []);

      this.filterData = [
        {
          type: 'category',
          title: 'Category',
          list: categories.data,
          isMultiple: true,
          activeFilters: existingFilters['category'] || []
        },
        {
          type: 'status',
          title: 'Status',
          list: statuses,
          isMultiple: true,
          activeFilters: existingFilters['status'] || []
        },
        {
          type: 'workflow_id',
          title: 'Workflow Process',
          list: workflowProcess.filter(schema => {
            return !schema.space_id || schema.space_id === this.getActiveSpace?.id
          }),
          isMultiple: true,
          activeFilters: existingFilters['workflow_id'] || []
        },
      ]

      return this.filterData
    },
    async searchProject(searchVal) {
      await this.$store.dispatch('setDashboardLoading', true);

      this.search = searchVal;
      this.$router.$updateQueryParams({search: searchVal, page: 1});

      await this.loadProjects();
      this.$store.dispatch('setDashboardLoading', false);
    },
    async setSort(key, value) {
      await this.$store.dispatch('setDashboardLoading', true);

      this[key] = value;
      this.$router.$updateQueryParams({[key]: value.slug, page: 1});

      await this.loadProjects();
      this.$store.dispatch('setDashboardLoading', false);
    },
    editFolder() {
      this.$store.dispatch('openModal', {
        modalName: 'createEditFolderNameModal',
        data: {
          type: 'edit',
          folderData: {
            title: this.getActiveFolder?.title || '',
            id: this.$route.params.id,
            space: this.getActiveSpace,
          },
        },
        handlers: {
          onUpdate: async () => {
            const spaces = await this.$api.spaces.list()
            await this.$store.dispatch('setSpaces', spaces.data);
            await this.$store.dispatch('setActiveFolder', this.$route.params.id)
          },
          onClose: () => {},
          onCreate: async () => {}
        },
      });
    },
  }
}
</script>
