<template>
  <div class="bulk-actions d-flex align-center">
    <div class="bulk-actions-counter text-captions-1">
      <strong class="font-weight-semi-bold accent--text text-body">({{ selectedProjects.length }})</strong>
      <span class="font-weight-semi-bold ml-1" style="margin-top: -2px;">Item(s) Selected</span>

      <div class="d-flex align-center mt-1" v-if="$vuetify.breakpoint.smAndUp">
        {{ projectItems.length }} Total Projects
        <span class="ml-1 font-weight-semi-bold">({{ projectItems.length }} Displayed)</span>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-3"
               @click="toggleAll(true)">
          Select All
        </UiBtn>

        <UiBtn text height="auto" color="accent" class="pa-0 font-weight-regular text-decoration-underline ml-2"
               @click="toggleAll(false)">
          Deselect
        </UiBtn>
      </div>
    </div>
    <div class="d-flex align-center ml-auto">
      <div class="d-flex align-center space-x-4" v-if="$vuetify.breakpoint.mdAndUp">
        <UiBtn outlined color="accent" :disabled="!canMoveProject" @click="onBulkMoveProject">
          Move
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="!projectsToComplete.length" @click="onCompleteProject">
          <IconCheck width="14" class="mr-2"/>
          Mark as complete
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="!projectsToDelete.length" @click="deleteProjects">
          <IconDelete width="14" class="mr-2"/>
          Delete
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="!projectsToArchive.length" @click="archiveProjects">
          <IconArchive width="14" class="mr-2"/>
          Archive
        </UiBtn>
        <UiBtn outlined color="accent" :disabled="!projectsToShare.length" @click="shareProjects">
          <IconShare width="14" class="mr-2"/>
          Share
        </UiBtn>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UiBtn from "@/components/UI/UiBtn";
import projectCompleteMixin from "@/mixins/projectCompleteMixin"

export default {
  name: 'BulkActionsBar',
  components: {
    IconDelete: () => import('@/components/icons/IconDelete'),
    IconArchive: () => import('@/components/icons/IconArchive'),
    IconShare: () => import('@/components/icons/IconShare'),
    IconCheck: () => import("@/components/icons/IconCheck"),
    UiBtn,
  },
  mixins: [projectCompleteMixin],
  props: {
    loadProjects: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'getDashboardFilters',
      'getProjectsList',
      'getPermission',
      'getActiveSpace'
    ]),
    projectItems() {
      return this.getProjectsList.filter(el => !el.is_folder)
    },
    selectedProjects() {
      return this.getProjectsList.filter(project => project.checked);
    },
    projectsToDelete() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-delete-dashboard']);
    },
    projectsToArchive() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-archive-dashboard'] && project.status !== this.$config.project.status.archived);
    },
    projectsToShare() {
      return this.selectedProjects.filter(project => this.getPermission(project.id).dashboard['can-share-dashboard']);
    },
    projectsToComplete() {
      return this.selectedProjects.filter(project => this.getPermission(project.id)['project-s-drawer']['complete-project']);
    },
    canMoveProject() {
      const {spaceAdmin} = this.$config.space.userRole;
      if (!this.getActiveSpace) {
        return false
      } else {
        return this.getActiveSpace.currentSpaceUser.user_role.name === spaceAdmin
      }
    },
  },
  methods: {
    toggleAll(val) {
      const projects = this.getProjectsList.map(project => {
        if(!project.is_folder) {
          return {...project, checked: val}
        }
        return project
      });
      this.$store.dispatch('setProjects', projects);
    },
    getLoadingProjectsId(projects) {
      const ids = projects.map(project => project.id);

      const loadingProjects = this.getProjectsList.map(project => ids.includes(project.id) ? {
        ...project,
        loading: true
      } : project);
      this.$store.dispatch('setProjects', loadingProjects);

      return ids;
    },
    deleteProjects() {
      if (!this.projectsToDelete.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to delete these projects?',
          description: 'If you delete these projects all their files will be archived and can be viewed through the files section',
          confirmBtnText: 'Delete',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            const ids = this.getLoadingProjectsId(this.projectsToDelete);

            try {
              await this.$api.project.bulkDelete({ids});

              const projects = this.getProjectsList.reduce((acc, project) => {
                if (!ids.includes(project.id)) {
                  acc.push({...project, loading: false});
                }
                return acc;
              }, []);
              await this.$store.dispatch('setProjects', projects);
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
    archiveProjects() {
      if (!this.projectsToArchive.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'confirmModal',
        data: {
          title: 'Are you sure you want <br> to archive this project?',
          description: 'Archiving your project will no longer appear in your project list',
          confirmBtnText: 'Archive',
        },
        handlers: {
          onCancel: () => {
            this.$store.dispatch('closeModal', 'confirmModal');
          },
          onConfirm: async () => {
            await this.$store.dispatch('closeModal', 'confirmModal');

            const ids = this.getLoadingProjectsId(this.projectsToArchive);

            try {
              const res = await this.$api.project.bulkUpdateStatus('archived', ids);
              const projectsToRemove = [];
              const projectsToReplace = res.reduce((acc, project) => {
                if (this.$store.getters.getDashboardFilters.status.includes(project.status.toString())) {
                  acc.push(project);
                } else {
                  projectsToRemove.push(project.id);
                }
                return acc;
              }, []);

              const projects = this.getProjectsList.reduce((acc, project) => {
                const updatedProject = projectsToReplace.find(rProject => rProject.id === project.id);
                if (updatedProject) {
                  acc.push(updatedProject);
                } else if (!projectsToRemove.includes(project.id)) {
                  acc.push(project);
                }
                return acc;
              }, []);

              await this.$store.dispatch('setProjects', projects);
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
    },
    shareProjects() {
      if (!this.projectsToShare.length) return;

      this.$store.dispatch('openModal', {
        modalName: 'shareProjectModal',
        data: {
          projects: this.projectsToShare
        }
      });
    },
    async onCompleteProject() {
      await this.completeProject(this.projectsToComplete.map(el => el.id), async () => {
        await this.loadProjects()
      })
    },
    onBulkMoveProject() {
      const projectsFolderID = this.selectedProjects?.[0]?.folder_id || this.selectedProjects?.[0]?.folder?.id
      const projectsSpaceID = this.selectedProjects?.[0]?.space_id
      const projectIds = this.selectedProjects.map(project => project.id)
      this.$store.dispatch('openModal', {
        modalName: 'moveProjectFolderModal',
        data: {
          type: 'project',
          ids: projectIds,
          folderId: projectsFolderID,
          spaceId: projectsSpaceID
        },
        handlers: {
          onUpdate: async () => {
            const promises = [this.$api.spaces.list()]

            if(this.$route.name === 'Space') {
              promises.push(this.$eventBus.$emit('reloadProjectsAndFolders'))
            } else {
              const projects = this.getProjectsList.filter(project => !projectIds.includes(project.id));
              await this.$store.dispatch('setProjects', projects);
            }
            const [spaces] = await Promise.all(promises)
            await this.$store.dispatch('setSpaces', spaces.data);
          },
          onClose: () => {},
          onCreate: () => {}
        },
      });
    }
  },
}
</script>

<style scoped lang="scss">
.bulk-actions {
  .bulk-actions-counter {
    .v-btn::before {
      display: none;
    }
  }
}
</style>
