<template>
  <div class="dashboard-project-list-view">
    <template v-for="item in $store.getters.getProjectsList">
      <ProjectListCard
        v-if="!item.is_folder"
        :key="item.id + '-p'"
        :project="item"
        :size="size"
      />
      <FolderListCard
        v-else
        :key="item.id"
        :folder="item"
        :size="size"
        :loadProjectsAndFolders="loadProjectsAndFolders"
      />
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProjectListCard from "@/components/project/ProjectListCard"
import FolderListCard from "@/components/folder/FolderListCard"

export default {
  name: 'ProjectListView',
  components: {
    ProjectListCard,
    FolderListCard,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    loadProjectsAndFolders: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsList',
    ]),
  },
}
</script>
