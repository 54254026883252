import { render, staticRenderFns } from "./UiListItemsFromTo.vue?vue&type=template&id=15dbd8ab&scoped=true&"
import script from "./UiListItemsFromTo.vue?vue&type=script&lang=js&"
export * from "./UiListItemsFromTo.vue?vue&type=script&lang=js&"
import style0 from "./UiListItemsFromTo.vue?vue&type=style&index=0&id=15dbd8ab&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15dbd8ab",
  null
  
)

export default component.exports