<template>
  <div :class="`card-wr ${size}`">
    <template v-for="item in $store.getters.getProjectsList">
      <ProjectGridCard
        v-if="!item.is_folder"
        :key="item.id + '-p'"
        :project="item"
        :size="size"
      />
      <FolderGridCard
        v-else
        :key="item.id"
        :folder="item"
        :size="size"
        :loadProjectsAndFolders="loadProjectsAndFolders"
      />
    </template>
  </div>
</template>

<script>
import ProjectGridCard from "@/components/project/ProjectGridCard"
import FolderGridCard from "@/components/folder/FolderGridCard"

export default {
  name: 'ProjectGridView',
  components: {
    ProjectGridCard,
    FolderGridCard
  },
  props: {
    size: {
      type: String,
      default: 'large'
    },
    loadProjectsAndFolders: {
      type: Function,
      default: () => {}
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wr {
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;

  &.large {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &.medium {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.small {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
</style>
