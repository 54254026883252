<template>
  <div class="items text-captions-1">{{from}}-{{ to }}<span class="of">of</span>{{total}}</div>
</template>

<script>
export default {
  name: "UiListItemsFromTo",
  props: {
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 12,
    },
    total: {
      type: Number,
      default: 150,
    },
  }
}
</script>

<style scoped lang="scss">
.items {
  font-weight: 500;
  color: var(--v-gray-100-base);
  .of {
    font-weight: 400;
    color: var(--v-gray-60-base);
    margin-left: 4px;
    margin-right: 4px;
  }
}
</style>
